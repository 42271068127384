<button class="header__sideBtn" on:click={() => openPage()}>About</button>
<Portal target="{document.body}">
    <ContextPage name="About" open={open} on:close={closePage}>
        <p class="txtbig">Questa radio riproduce il contenuto dei toot pubblicati su una o più istanze <a href="https://joinmastodon.org/" target="_blank">Mastodon</a>. Il software originale è stato creato dal collettivo <a href="https://github.com/EldritchCafe" target="_blank">Eldritch Café</a> mentre questa versione è tradotta e gestita da Kenobit e Ed per <a href="https://livellosegreto.it/" target= "_blank">Livello Segreto</a>.</p>
        <hr>
        <h2>Da dove arriva la musica?</h2>
        <p>L'app prende tutti i toot pubblici che contengano un link multimediale e un determinato hashtag (configurabile da "Impostazioni"). Per ora sono supportati solamente i link Youtube, ma l'idea è di espandere ad altri servizi in futuro.<br>
        Non abbiamo i diritti per riprodurre le canzoni che vengono quindi eseguite direttamente dalla piattaforma d'origine.</p>
        <!--<h2>Posso riprodurre la mia canzone qui?</h2>
        <p>Certo! Per farlo basta fare un toot pubblico su <a href="https://livellosegreto.it/" target= "_blank">Livello Segreto</a> che contenga un link di Youtube e l'hashtag #canzoneSegreta.</p>
        <h2>Can I use the radio on my phone?</h2>
        <p>It’s a webapp so it should be working, however since the Youtube Player allows us to play a song only when you’re on the web and stops when the screen turns off or when you’re on another app, it’s kind of unusable.<br>
        It’s kind of shitty but as long Youtube has this behavior we don’t see a way to make a great experience for the radio on mobile devices.</p>-->
        <h2>Come contattarvi se c'è un problema o se ho un'idea?</h2>
        Puoi trovare su <a href="https://github.com/EldritchCafe/radio" target="_blank">Github</a> il repository originale sul quale segnalare problemi o proporre idee lato software. Per problemi su questa istanza o per segnalare errori di traduzione contatta pure Kenobit o Ed su <a href="https://livellosegreto.it/" target= "_blank">Livello Segreto</a>.
    </ContextPage>
</Portal>

<script>
    import Portal from 'svelte-portal'
    import ContextPage from '/src/components/ContextPage'

    let open = false

    let openPage = () => {
        open = true
    }

    let closePage = () => {
        open = false
    }
</script>
